exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-browse-js": () => import("./../../../src/pages/browse.js" /* webpackChunkName: "component---src-pages-browse-js" */),
  "component---src-pages-de-browse-js": () => import("./../../../src/pages/de/browse.js" /* webpackChunkName: "component---src-pages-de-browse-js" */),
  "component---src-pages-de-index-js": () => import("./../../../src/pages/de/index.js" /* webpackChunkName: "component---src-pages-de-index-js" */),
  "component---src-pages-de-showcase-js": () => import("./../../../src/pages/de/showcase.js" /* webpackChunkName: "component---src-pages-de-showcase-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-showcase-js": () => import("./../../../src/pages/showcase.js" /* webpackChunkName: "component---src-pages-showcase-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-noun-template-english-js": () => import("./../../../src/templates/nounTemplateEnglish.js" /* webpackChunkName: "component---src-templates-noun-template-english-js" */),
  "component---src-templates-noun-template-js": () => import("./../../../src/templates/nounTemplate.js" /* webpackChunkName: "component---src-templates-noun-template-js" */)
}

