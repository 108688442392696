import React from "react"
import { graphql, Link } from "gatsby"
const NounLink = ({ children }) => {

    return (
      <Link to={`/${children}`}>
        {children}
      </Link>
    );
  };
  export default NounLink